import React from 'react';

function Iletisim() {
  return (
    <div className="w-full h-auto min-h-[60vh] flex flex-col justify-center items-center bg-gradient-to-t from-gray-100 via-gray-200 to-gray-300 p-4 md:p-8 lg:p-12">
      <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-center mb-8 border-b-2 border-black border-dotted">İLETİŞİM</h1>
      <p className="text-2xl font-freeman">TELEFON NUMARALARIMIZ</p>
      <p className="text-xl font-freeman">0540 453 5345</p>
      <p className="text-xl font-freeman">0541 535 3561</p>
      <a href="tel:905404535345" className="mt-8 px-6 py-3 bg-blue-500 text-white rounded-lg shadow-md hover:bg-blue-600 md:hidden">TIKLA ARA</a>
      <p className="text-2xl font-freeman pt-6">İŞYERİ ADRESİMİZ</p>
      <p className="text-xl font-freeman">Gaziler Mahallesi 600. Sokak No:31 Manisa/Salihli</p>
      <div className="w-full h-64 md:h-96 mt-6">
        <iframe
          width="100%"
          height="100%"
          frameBorder="0"
          style={{ border: 0 }}
          src="https://www.google.com/maps/embed/v1/place?q=ÖZTÜRK%20KERESTE%2C%20Gaziler%2C%20600.%20Sk.%20NO%3A31%2C%2045300%20Salihli%2FManisa&key=AIzaSyAJyea_T0j-3UKrw8ydGTRZbcGDybSg9K4"
          allowFullScreen>
        </iframe>
      </div>
    </div>
  );
}

export default Iletisim;