import React from 'react';

function HakkimizdaMain() {
  return (
    <div className="w-full h-[60vh] flex flex-col justify-center items-center bg-gradient-to-t from-gray-100 via-gray-200 to-gray-300">
      <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-center mb-8 border-b-2 border-black border-dotted">HAKKIMIZDA</h1>
      <p className="max-w-lg text-center px-8 md:px-0">Öztürk Kereste olarak, sürdürülebilir orman yönetimi ilkelerine bağlı kalarak, çevreye duyarlı bir şekilde çalışıyoruz. Doğal kaynakları koruma ve gelecek nesillere daha iyi bir dünya bırakma taahhüdümüzü her adımda gösteriyoruz. Projelerinizi gerçekleştirmek için ihtiyacınız olan her şeyi bulabileceğiniz Öztürk Kereste'ye hoş geldiniz!</p>
      <a href="/hakkimizda"><button  className="mt-8 px-6 py-3 bg-blue-500 text-white rounded-lg shadow-md hover:bg-blue-600">Devamını Oku</button></a>
    </div>
  );
}

export default HakkimizdaMain;