import React, { useState } from 'react';

function Galeri() {
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const images = [
    `${process.env.PUBLIC_URL}/galeri1.jpeg`,
    `${process.env.PUBLIC_URL}/galeri2.jpeg`,
    `${process.env.PUBLIC_URL}/galeri3.jpeg`,
    `${process.env.PUBLIC_URL}/galeri4.jpeg`,
    `${process.env.PUBLIC_URL}/galeri5.jpeg`,
    `${process.env.PUBLIC_URL}/galeri6.jpeg`,
    `${process.env.PUBLIC_URL}/galeri7.jpeg`,
    `${process.env.PUBLIC_URL}/galeri8.jpeg`,
    `${process.env.PUBLIC_URL}/galeri9.jpeg`,
  ];

  const openImage = (index) => {
    setSelectedImageIndex(index);
  };

  const closeImage = () => {
    setSelectedImageIndex(null);
  };

  const nextImage = (e) => {
    e.stopPropagation();
    setSelectedImageIndex((selectedImageIndex + 1) % images.length);
  };

  const prevImage = (e) => {
    e.stopPropagation();
    setSelectedImageIndex((selectedImageIndex - 1 + images.length) % images.length);
  };

  return (
    <div className="w-full h-auto min-h-[60vh] flex flex-col justify-center items-center bg-gradient-to-t from-gray-100 via-gray-200 to-gray-300 p-4 md:p-8 lg:p-12">
      <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-center mb-8 border-b-2 border-black border-dotted">GALERİ</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {images.map((image, index) => (
          <div key={index} className="border-2 border-gray-400 rounded-lg overflow-hidden cursor-pointer" onClick={() => openImage(index)}>
            <img src={image} alt={`Galeri ${index}`} className="w-full h-full object-cover" />
          </div>
        ))}
      </div>

      {selectedImageIndex !== null && (
        <div className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center z-50" onClick={closeImage}>
          <div className="relative">
            <button onClick={closeImage} className="absolute top-4 right-4 text-white text-3xl z-10">&times;</button>
            <button onClick={prevImage} className="absolute top-1/2 left-4 text-white text-3xl z-10 transform -translate-y-1/2">&#10094;</button>
            <button onClick={nextImage} className="absolute top-1/2 right-4 text-white text-3xl z-10 transform -translate-y-1/2">&#10095;</button>
            <img src={images[selectedImageIndex]} alt="Selected" className="w-full max-w-4xl max-h-screen object-contain" onClick={(e) => e.stopPropagation()} />
          </div>
        </div>
      )}
    </div>
  );
}

export default Galeri;