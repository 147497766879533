import React from 'react';

function Hakkimizda() {
  return (
    <div className="w-full h-[75vh] flex flex-col justify-center items-center bg-gradient-to-t from-gray-100 via-gray-200 to-gray-300 p-4 md:p-8 lg:p-12">
      <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold text-center mb-4 md:mb-6 border-b-2 border-black border-dotted">HAKKIMIZDA</h1>
      <p className="max-w-screen-lg text-sm md:text-base lg:text-lg text-center px-4 md:px-0">
        Şirketimiz, kurucularından dedemiz İsmail ÖZTÜRK tarafından 1970 yılında kurulmuş olup, ticaret hayatını Mustafa ÖZTÜRK tarafından devam ettirmektedir. Öztürk Kereste, 50 yılı aşkın süredir edindiği tecrübe ve bilgi birikimi ile orman ürünlerinde önemli bir konum elde etmiştir. Kuruluşundan itibaren Türkiye'nin dört bir yanına kereste hizmeti sunmaktadır. Kaliteden ve müşteri memnuniyetinden ödün vermemeyi ilke edinen şirketimiz, ticaret hayatına devam etmektedir.

        Şirketimiz, her geçen gün teknoloji ve politikalarıyla kendini yenileyerek en iyi hizmeti sunmayı ilke edinmiştir. Öztürk Kereste, müşteri memnuniyeti odaklı yaklaşımıyla gelişmeyi, evrensel kalite ve standartlarda ürün ve hizmetler sunmayı amaçlamaktadır. Bu sayede ülkesi ve müşterileri için güvenilirlik, devamlılık ve saygınlık simgesi olmayı hedeflemektedir. Müşterilerimiz için değer yaratmak, beklentilerine kalite ve istikrarla karşılık vermek ilk önceliğimizdir. Ürün ve hizmetlerimize sahip çıkmak ve sonrasında da müşterilerimizin yanında olmak görevimizdir.
      </p>
    </div>
  );
}

export default Hakkimizda;