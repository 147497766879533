import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/header';
import Main from './pages/main';
import Footer from './components/footer';
import Hakkimizda from './pages/hakkimizda';
import Iletisim from './pages/iletisim';
import Galeri from './pages/galeri';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route exact path="/" element={<Main/>}/>
          <Route exact path="/hakkimizda" element={<Hakkimizda/>}/>
          <Route exact path="/iletisim" element={<Iletisim/>}/>
          <Route exact path="/fotogaleri" element={<Galeri/>}/>
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;