import React, { useEffect, useRef } from 'react';
import HakkimizdaMain from '../components/hakkimizdamain';
import UrunlerMain from '../components/urunlermain';

function Main() {
  const backgroundImages = useRef(["background1.png", "background2.png", "background3s.png"]);
  const backgroundIndex = useRef(0);
  const backgroundImageURL = `${process.env.PUBLIC_URL}/${backgroundImages.current[backgroundIndex.current]}`;

  useEffect(() => {
    const interval = setInterval(() => {
      // Değişken index'i arttırır ve dizi sınırını kontrol eder
      backgroundIndex.current = (backgroundIndex.current + 1) % backgroundImages.current.length;
      // Arka plan görüntüsünü günceller
      const container = document.getElementById("background-container");
      container.style.backgroundImage = `url(${process.env.PUBLIC_URL}/${backgroundImages.current[backgroundIndex.current]})`;
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
    <div className='flex flex-col'>
    <div className="w-full h-[80vh] flex justify-center items-center relative">
      {/* Arka plan görüntüsü */}
      <div id="background-container" className="w-full h-[80vh] bg-cover bg-center absolute top-0 left-0" style={{ backgroundImage: `url(${backgroundImageURL})`, opacity: 0.5 }}></div>
      
      {/* İçerik */}
      <div className="relative z-10 text-center flex flex-col md:flex-row justify-center items-center">
        <img alt="" src="ozturkkerestelogo.png" className="w-[350px] h-[350px] pt-8 mt-8 md:hidden"></img>
        <div className="w-full flex flex-col md:flex-row justify-center items-center gap-12 md:pt-0">
          {/* Logo - Desktop */}
          <img alt="" src="ozturkkerestelogo.png" className="w-[500px] h-[500px] hidden md:block md:mx-auto"></img>
          <div className="flex flex-col justify-center items-center md:items-start gap-6 m-6 max-w-[780px]">
            <h1 className="font-freeman antialiased text-4xl md:text-left">Öztürk Kereste dünya standartlarında kerestecilik sunar.</h1>
            <button className="w-40 h-16 bg-transparent border-2 border-amber-600 rounded-xl shadow-lg text-2xl font-freeman text-black hover:text-amber-600"><a href="/iletisim">BİZE ULAŞIN</a></button>
          </div>
        </div>
      </div>
    </div>
    <HakkimizdaMain/>
    <UrunlerMain/>
    </div>
    </>
    

  );
}

export default Main;