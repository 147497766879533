import React from 'react';

function UrunlerMain() {
  return (
    <div className="bg-white text-black" id="drops">
        <div className="relative text-center py-4 text-black">
          <h1 className="text-4xl font-bold">ÜRÜNLERİMİZ</h1>
        </div>
        <div className="flex flex-wrap justify-center content-center items-center gap-4 py-16 text-center px-6">
          <div className="w-[450px] h-[550px] border-4 bg-gradient-to-br from-gray-200 to-gray-300 border-black rounded-lg">
            <div className="flex justify-center content-center items-center w-full h-[450px] border-b-4 border-black">
              <img alt="" src="kavakkereste.jpeg" className="h-full w-full"></img>
            </div>
            <div className="flex flex-col py-6">
              <span className="font-bold text-4xl">KAVAK KERESTE</span>
            </div>
          </div>
          <div className="w-[450px] h-[550px] border-4 bg-gradient-to-br from-gray-200 to-gray-300 border-black rounded-lg hidden md:block">
            <div className="flex justify-center content-center items-center object-cover w-full h-[450px] border-b-4 border-black">
              <img alt="" src="camkereste.jpeg" className="h-full w-full"></img>
            </div>
            <div className="flex flex-col py-6">
              <span className="font-bold text-4xl">ÇAM KERESTE</span>
            </div>
          </div>
        </div>
      </div>
  );
}

export default UrunlerMain;