import React, { useState } from 'react';

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="z-30 relative">
      <div className="bg-gray-200 py-4 px-4 border-b-4 border-gray-700 shadow-4xl relative z-30">
        <div className="container mx-auto flex justify-between items-center">
          <div className='flex font-semibold antialiased'>
            <img src="ozturkkerestelogo.png" alt="Logo" className="h-16" />
            <a href="/" className="text-black pt-4 px-6 text-xl font-freeman mt-1">ÖZTÜRK KERESTE</a>
          </div>
          <div className="hidden md:flex items-center space-x-4 font-semibold antialiased">
            <a href="/" className="text-black hover:text-amber-600 rounded py-4 px-6">ANASAYFA</a>
            <a href="/hakkimizda" className="text-black hover:text-amber-600 rounded py-4 px-6">HAKKIMIZDA</a>
            <a href="/iletisim" className="text-black hover:text-amber-600 rounded py-4 px-6">İLETİŞİM</a>
            <a href="/fotogaleri" className="text-black hover:text-amber-600 rounded py-4 px-6">GALERİ</a>
          </div>
          <div className="md:hidden">
            <button onClick={toggleMenu} className="text-black focus:outline-none ">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="fixed top-0 right-0 h-full w-2/4 bg-gray-200 shadow-2xl text-black p-4 transform transition duration-300 ease-in-out z-40">
          <div className="flex flex-col items-center space-y-4 font-semibold antialiased">
            <div className='items-end justify-items-end w-full'>
              <button onClick={toggleMenu} className="text-black focus:outline-none py-4 px-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 items-end" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                </svg>
              </button>
            </div>
            <a href="/" className="text-black hover:text-amber-600 py-4 px-6 border-b-2 border-gray-500 w-full hover:border-black text-center">ANASAYFA</a>
            <a href="/hakkimizda" className="text-black hover:text-amber-600 py-4 px-6 border-b-2 border-gray-500 w-full hover:border-black text-center">HAKKIMIZDA</a>
            <a href="/iletisim" className="text-black hover:text-amber-600 py-4 px-6 border-b-2 border-gray-500 w-full hover:border-black text-center">İLETİŞİM</a>
            <a href="/fotogaleri" className="text-black hover:text-amber-600 py-4 px-6 border-b-2 border-gray-500 w-full hover:border-black text-center">GALERİ</a>
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;